.ui.menu {
  border-color: transparent !important;
  margin-top: 5% !important;
  background-color: #191a1d;
  box-shadow: none;
  font-size: 1.5rem;
}

.item {
  border-right: 0px !important;
  color: white !important;
}
.header {
  color: lightseagreen !important;
  padding-right: 20% !important;
  border: transparent !important;
}
